const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['dlvry-adventureworks'] = {
        c: () => require('partner/themes/dlvry-adventureworks/dlvry-adventureworks.tsx'),
        $type: 'themeModule',
        da: [{name:'address',  path:'actions/get-organization-address.action', moduleName: 'account-address-tile',runOn: 0},{name:'address',  path:'actions/get-organization-address.action', moduleName: 'account-management-address',runOn: 0},{name:'cart',  path:'@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action', moduleName: 'product-collection',runOn: 0},{name:'channelInventoryConfiguration',  path:'actions/get-inventory-configuration.action', moduleName: 'cart',runOn: 0},{name:'customerInformation',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer', moduleName: 'product-collection',runOn: 0},{name:'customerInformation',  path:'actions/get-organization-customer', moduleName: 'account-management-address',runOn: 0},{name:'organizationInformation',  path:'actions/get-organization-customer', moduleName: 'account-welcome-tile',runOn: 0},{name:'wishlists',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id', moduleName: 'product-collection',runOn: 1}],
        definitionExtensions: ['account-address-tile','account-generic-tile','account-management-address','account-welcome-tile','active-image','b2b-requests-status','business-organization-list','business-sign-up','buybox','cart-icon','cart','checkout-shipping-address','content-block','header','image-list','navigation-menu','order-confirmation','order-history','order-template-list','order-template','product-collection','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','sign-in','store-selector','text-block','wishlist-items','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'dlvry-adventureworks',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'dlvry-adventureworks.theme.settings.json',
        md: 'src/themes/dlvry-adventureworks'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action';
            let dataAction = require('@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-inventory-configuration.action';
            let dataAction = require('partner/actions/get-inventory-configuration.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-organization-address.action';
            let dataAction = require('partner/actions/get-organization-address.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-organization-address.action';
            let dataAction = require('partner/actions/get-organization-address.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-organization-customer';
            let dataAction = require('partner/actions/get-organization-customer');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-organization-customer';
            let dataAction = require('partner/actions/get-organization-customer');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };